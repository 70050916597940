import { MatchList } from "../../api/CompetitionList.js";
export default {
  name: "CompetitionList",
  data() {
    return {
      MatchListData: [],
      Params: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
      },
      Total: 0,
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata() {
      MatchList(this.Params).then((res) => {
        this.MatchListData = res.data.list;
        this.Total = Number(res.data.total);
      });
    },
    // 指定项目跳转
    ProjectJump(item) {
      // if (item.challenge_status == 1) {
      //   this.$message.error("当前比赛已结算，请下次开放时参加！");
      //   return;
      // }
      this.$router.push({
        path: "/SingleItem",
        query: {
          id: item.challenge_id,
          matchstate: item.challenge_status
        },
      });
    },
  },
};
